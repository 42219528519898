import { Controller } from '@hotwired/stimulus'
import { encodeData, decodeData } from '../features/uri_encoder_utils'

interface PromptItem {
  role: string;
  content: string;
}

// Type guard for PromptItem array
function isPromptItemArray(items: unknown): items is PromptItem[] {
  return (
    Array.isArray(items) &&
    items.every(item => item && typeof item == 'object')
  );
}

interface ModelsData {
  [key: string]: string[];
}

export default class extends Controller {
  static targets = ['promptRows', 'promptJson', 'rowTemplate', 'providerSelect', 'modelSelect', 'modelsData']

  declare readonly promptRowsTarget: HTMLElement
  declare readonly promptJsonTarget: HTMLInputElement
  declare readonly rowTemplateTarget: HTMLTemplateElement
  declare readonly providerSelectTarget: HTMLSelectElement
  declare readonly modelSelectTarget: HTMLSelectElement
  declare readonly modelsDataTarget: HTMLInputElement

  connect(): void {
    this.loadExistingData()
    this.updatePromptJson()
    this.updateModelOptions()
  }

  loadExistingData(): void {
    const value = this.promptJsonTarget.value
    if (!value) return
    
    const parsedValue = decodeData(value)
    
    // Check if it's an array of PromptItems
    if (!isPromptItemArray(parsedValue)) {
      console.error('Invalid data format')
      return
    }

    try {
      this.promptRowsTarget.innerHTML = ''
      parsedValue.forEach((item) => {
        this.addPromptRow(null, item)
      })
    } catch (e) {
      console.error('Failed to load prompt data:', e)
    }
  }

  addPromptRow(event: Event | null, data: PromptItem | null = null): void {
    if (event) event.preventDefault()
    const newRow = this.rowTemplateTarget.content.cloneNode(true) as HTMLElement
    if (data) {
      const roleSelect = newRow.querySelector<HTMLSelectElement>('select[name="ai_prompt[prompt][][role]"]')
      const contentTextarea = newRow.querySelector<HTMLTextAreaElement>('textarea[name="ai_prompt[prompt][][content]"]')
      if (roleSelect) roleSelect.value = data.role
      if (contentTextarea) contentTextarea.value = data.content
    }
    this.promptRowsTarget.appendChild(newRow)
    this.updatePromptJson()
  }

  removeRow(event: Event): void {
    event.preventDefault()
    const target = event.target as HTMLElement
    const row = target.closest('.prompt-row')
    if (row) {
      row.remove()
      this.updatePromptJson()
    }
  }

  updatePromptJson(): void {
    const promptData: PromptItem[] = []
    this.promptRowsTarget.querySelectorAll('.prompt-row').forEach((row) => {
      const roleSelect = row.querySelector<HTMLSelectElement>('select[name="ai_prompt[prompt][][role]"]')
      const contentTextarea = row.querySelector<HTMLTextAreaElement>('textarea[name="ai_prompt[prompt][][content]"]')

      if (roleSelect && contentTextarea) {
        const item = {
          role: roleSelect.value,
          content: contentTextarea.value
        }
        promptData.push(item)
      }
    })

    this.promptJsonTarget.value = encodeData(promptData)
  }

  updateModelOptions(): void {
    const provider = this.providerSelectTarget.value
    const modelsData: ModelsData = JSON.parse(this.modelsDataTarget.value)
    const models = modelsData[provider] || modelsData['default'] || []
  
    const currentlySelectedModel = this.modelSelectTarget.value
  
    this.modelSelectTarget.innerHTML = ''
  
    models.forEach(model => {
      const option = document.createElement('option')
      option.value = model
      option.textContent = model === '' ? 'Default' : model
      this.modelSelectTarget.appendChild(option)
    })
  
    if (models.includes(currentlySelectedModel)) {
      this.modelSelectTarget.value = currentlySelectedModel
    } else {
      this.modelSelectTarget.selectedIndex = 0
    }
  
    this.modelSelectTarget.setAttribute('data-previous-value', this.modelSelectTarget.value)
  }

  submitForm(event: Event): void {
    event.preventDefault()
    this.updatePromptJson()
    const form = event.target as HTMLFormElement
    form.submit()
  }  
}