// Utility functions for encoding/decoding
export const encodeData = (data: unknown): string => {
  try {
    const jsonString = JSON.stringify(data)
    // Convert to Unicode escape sequences
    return encodeURIComponent(jsonString)
  } catch (e) {
    console.error('Encoding failed:', e)
    return ''
  }
}

export const decodeData = (value: string): unknown => {
  if (!value) return null
  
  try {
    // Convert from Unicode escape sequences
    const jsonString = decodeURIComponent(value)
    return JSON.parse(jsonString)
  } catch (e) {
    console.error('Decoding failed:', e)
    return null
  }
}